.footer__container {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    line-height: 20px;
    padding-left: 56px;
    position: relative;
    max-width: 806px;
    width: 100%;

    &::before {
      position: absolute;
      content: "";
      background-image: url("./footer_logo@2x.webp");
      width: 40px;
      height: 40px;
      background-size: contain;
      top: 0;
      left: 0;
    }
  }

  span {
    font-size: 14px;
    line-height: 20px;
  }
}

@media(max-width: $mobile-width) {
  .footer__container {
    flex-direction: column;
    align-items: start;
    gap: 20px;
    padding: 0 0 60px 0;

    div {
      padding: 0;

      &::before {
        display: none;
      }
    }
  }
}