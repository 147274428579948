.notfound {
  display: flex;
  flex-grow: 1;

  & .container {
    display: flex;
    flex-grow: 1;
    height: auto;
    justify-content: center;
  }
}

.notfound__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 50px 0;

  h2 {
    font-size: clamp(18px, 2vw, 32px);
    font-family: $ff-unbound;
    line-height: 40px;
  }

  p {
    font-size: clamp(14px, 1.5vw, 20px);
    line-height: 28px;
    text-align: center;
    font-weight: 500;
    max-width: 700px;
  }

  svg {
    width: 400px;
    height: 400px;
  }

  a {
    max-width: 300px;
    text-align: center;
    width: 100%;
  }
}

.notfound__animation {
  max-width: 300px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: $mobile-width) {
  .notfound__container {
    gap: 20px;
    padding: 20px 0;

    a {
      max-width: 100%;
      width: 100%;
    }
  }

  .notfound__animation {
    max-width: 250px;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}