@font-face {
  font-family: 'PT_Sans';
  src: url('../../fonts/PT_Sans-Caption-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PT_Sans';
  src: url('../../fonts/PT_Sans-Caption-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Unbounded';
  src: url('../../fonts/Unbounded-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

