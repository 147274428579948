.tests__container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 8px;
    row-gap: 24px;
  }

  li {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    max-width: 292px;
    min-width: 250px;
    width: 100%;
    border-radius: 30px;
    min-height: 208px;
    background-size: contain;

    &.face {
      background-image: url("./face_bg.png");
    }

    &.nails {
      background-image: url("./nails_bg.png");
    }

    &.skin {
      background-image: url("./skin_bg.png");
    }

    &.hair {
      background-image: url("./hair_bg.png");
    }

    &.makeup {
      max-width: 100%;
      background-image: url("./makeup_bg.png");
      position: relative;
      background-size: cover;
      width: 100%;
      grid-column: -1/1;

      div {
        max-width: 570px;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      img {
        position: absolute;
        top: -50px;
        right: 0;
        width: 680px;
        height: 372px;
        object-fit: cover;
      }

      a {
        max-width: fit-content;
        gap: 15px;
      }
    }

    h3 {
      font-size: clamp(20px, 1.5vw, 24px);
      font-family: $ff-unbound;
      line-height: clamp(24px, 2vw, 32px);
    }

    p,
    span {
      font-size: 16px;
      line-height: 24px;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;
    }

    a {
      max-width: fit-content;
      gap: 15px;
      margin-top: auto;
    }
  }
}

.tests__top {
  display: flex;
  flex-direction: column;

  a {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    color: $text-color-head;
    width: fit-content;
    border-radius: 350px;
    padding: 10px;
    transition: 0.2s;

    svg {
      width: 8px;
      height: 10px;
      transform: rotate(180deg);
    }

    &:hover,
    &:focus {
      outline: $hover;
    }

    &:active {
      outline: none;
      color: $color-active;
    }
  }

  h2 {
    font-size: $fs-heading;
    line-height: $lh-heading;
    font-family: $ff-unbound;
  }
}

@media(max-width: $tablet-width) {
  .tests__container {
    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      max-width: 49%;
      background-repeat: no-repeat;
      background-size: cover;

      &.makeup {
        img {
          display: none;
        }
      }
    }
  }
}

@media(max-width: $mobile-width) {
  .tests__container {
    li {
      max-width: 100%;

      &.makeup {
        flex-direction: column-reverse;
        align-items: center;

        img {
          width: auto;
          height: auto;
          position: static;
          display: block;
          min-width: 255px;
          min-height: 232px;
          object-fit: contain;
        }
      }
    }
  }
}