.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.modal {
  background: transparent;
  position: relative;
  max-width: fit-content;
  width: 100%;
  animation: slideIn 0.3s ease-out;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: clamp(16px, 1.5vw, 24px);
    line-height: clamp(20px, 1.5vw, 30px);
    font-weight: 900;
    padding-right: 30px;
  }
}

.modal__close {
  background-color: $color-default-white;
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  width: 42px;
  height: 42px;
  z-index: 1000;
  border-radius: 7px;
  color: $color-default-black;

  svg {
    width: 12px;
    height: 12px;
  }

  &:hover,
  &:focus {
    background-color: $color-default-black;
    color: $color-default-white;
  }

  &:active {
    transform: scale(0.9);
  }
}

.modal__content {
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: auto;
  flex: 1;
  overflow-y: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }

  to {
    transform: translateY(0);
  }
}

@media (max-width: $mobile-width) {
  .modal__overlay {
    align-items: flex-end;
    justify-content: center;
  }

  .modal {
    height: auto;
    max-width: 100%;
    width: 100%;
    background: #fff;
    border-radius: 16px 16px 0 0;
    max-height: calc(100vh - env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
    overflow-y: auto;
  }

  .modal__content {
    flex: none;
    position: relative;
  }
}