.survey {
  width: 100%;
  flex: 1;
}

.survey__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  max-width: 492px;
}

.survey__top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a,
  button {
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;

    svg {
      transform: rotate(180deg);
      width: 10px;
      height: 14px;
    }

    &:hover,
    &:focus {
      transform: $trans-hover;
    }

    &:active {
      transform: $trans-active;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    font-family: $ff-unbound;
  }
}

.survey__progress {
  svg {
    text {
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
    }
  }
}

.survey__question {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;

  h2 {
    font-size: 20px;
    line-height: 28px;
    font-family: $ff-unbound;
  }

  .spinner {
    height: 100%;
    justify-content: center;
  }
}

.survey__answers {
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px;
    background-color: $color-default-white;
  }

  button {
    background-color: transparent;
    border: none;
    display: flex;
    max-width: 50%;
    width: 100%;
    padding: 6px 0 6px 16px;
    cursor: pointer;
    gap: 10px;
    align-items: center;
    border-radius: 10px 0 0 10px;
    height: 100%;
    text-align: left;
    text-wrap: wrap;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background-color: $body-color;
      color: #1175DA;
      outline: $hover;
    }

    &:not(:disabled):active {
      transform: $trans-active;
    }

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    svg {
      min-width: 14px;
    }
  }

  label {
    padding: 6px 16px 6px 0;
    width: 50%;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    transition: $trans-default;

    input {
      display: none;
    }

    span {
      display: flex;
      border-radius: 50%;
      border: 1px solid $text-color-head;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
    }

    input[type="radio"]:checked+span::before {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      background-color: $text-color-head;
      border-radius: 50%;
    }

    &:hover,
    &:focus {
      background-color: $body-color;
      color: #1175DA;
      outline: $hover;

      & span {
        border-color: #1175DA;
      }

      input[type="radio"]:checked+span::before {
        background-color: #1175DA;
      }
    }

    &:active {
      transform: $trans-active;
    }
  }
}

.survey__check {
  label {
    span {
      border-radius: 7px;

      svg {
        display: none;
      }
    }

    input[type="checkbox"]:checked+span svg {
      display: block;
      color: $text-color-head;
    }
  }
}

.survey .survey__number {
  background-color: transparent;

  label {
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      background-color: inherit;
      color: inherit;
      outline: none;
      transform: none;
    }


    input {
      display: flex;
      width: 100%;
      min-height: 56px;
      padding: 8px;
      border: 1px solid $text-color-header;
      border-radius: 10px;
      font-size: 16px;
      color: $text-color-head;

      &:focus {
        outline: 2px solid #1175DA;
      }
    }
  }
}

.survey__navigation {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;

  button {
    min-height: 56px;
    min-width: 107px;
  }
}

.survey__modal {
  min-height: 244px;
  background-color: $body-color;
  border-radius: 20px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    padding-right: 50px;
    font-size: 20px;
    line-height: 28px;
    font-family: $ff-unbound;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
  button {
    margin-top: auto;
  }
}

@media (max-width: $no-dashboard) {
  .survey__container {
    max-width: 100%;
    padding: 40px 0;
  }

  .survey__question {
    gap: 30px;

    h2 {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .survey__answers {
    gap: 20px;
  }

  .survey__navigation {
    margin-top: auto;
  }
}