.header__container {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {

    &:hover,
    &:focus {
      transform: $trans-hover;
    }

    &:active {
      transform: $trans-active;
    }
  }

  img {
    width: 167px;
    height: 40px;
  }

  nav {
    ul {
      display: flex;
      align-items: center;
      gap: 32px;

      li:not(:first-child) {
        svg {
          display: none;
        }
      }

      a {
        color: $text-color-header;
        display: flex;
        gap: 6px;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        padding: 1px 0;

        &.current {
          border-bottom: 1px solid $text-color-header;
          font-weight: 700;
        }
      }
    }
  }
}

.header__lang {
  display: flex;
  align-items: center;
  gap: 24px;

  button {
    color: $text-color-head;
    font-size: 14px;
    line-height: 20px;
    padding: 1px 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &.current {
      border-bottom: 1px solid $color-default-black;
      font-weight: 700;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      transform: $trans-hover;
    }

    &:not(:disabled):active {
      transform: $trans-active;
    }

    &:disabled {
      opacity: 0.1;
      cursor: not-allowed;
    }
  }
}

.header__burger {
  display: none;
}

@media(max-width: $mobile-width) {
  .header {
    background-color: $color-default-white;
  }

  .header__container {
    padding: 12px 0;
    position: relative;
    flex-wrap: wrap;

    a {
      order: 1;
      width: 128px;
      height: 32px;

      img {
        object-fit: contain;
      }
    }

    nav {
      overflow: hidden;
      max-height: 0;
      padding: 0;
      opacity: 0;
      display: flex;
      flex-direction: column;
      transition: max-height 0.7s ease, opacity 0.4s ease;
      width: calc(100% + 30px);
      left: -15px;
      border-bottom: 1px solid #d9d9d9;
      order: 4;
      position: absolute;
      top: 56px;
      z-index: 1000;
      background-color: $color-default-white;

      ul {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        padding-left: 10px;
      }

      a {
        width: fit-content;
      }

      &.nav--open {
        max-height: 500px;
        opacity: 1;
        padding: 40px 0;
      }
    }
  }

  .header__lang {
    order: 2;
  }

  .header__burger {
    position: relative;
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 3;

    span,
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $text-color-header;
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    span {
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      top: 6px;
    }

    &:after {
      bottom: 6px;
    }

    &.burger--open {
      span {
        opacity: 0;
      }

      &:before {
        transform: rotate(45deg) translateY(-1px);
        top: 50%;
      }

      &:after {
        transform: rotate(-45deg) translateY(1px);
        bottom: 50%;
      }
    }
  }
}