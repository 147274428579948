.error {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 15px;

  &::before {
    position: absolute;
    content: "";
    width: 728px;
    height: 827px;
    z-index: 0;
    left: 0;
  }

  &::after {
    position: absolute;
    content: "";
    width: 728px;
    height: 827px;
    right: -144px;
    bottom: 0;
    z-index: 0;
  }

  img {
    display: block;
    width: 250px;
    height: 250px;
    object-fit: contain;
    margin: 0 auto;
  }
}

.error__content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  z-index: 5;

  h2 {
    font-size: 24px;
  }

  span {
    font-size: 18px;
    color: #474646;
  }

  p {
    font-size: 14px;
    color: #808080;
  }
}
