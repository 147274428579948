.btn {
  cursor: pointer;
  border-radius: 15px;
  background-color: $color-default-black;
  color: $color-default-white;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  font-size: 14px;
  line-height: 20px;
  border: none;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus {
    outline-offset: 2px;
    outline: $hover;
  }

  &:not(:disabled):not(.disabled):active {
    transform: $trans-active;
  }

  &:disabled {
    background-color: $color-default-white;
    color: $text-color-head;
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &--white {
    background-color: $color-default-white;
    border: none;
    color: $text-color-head;

    &:disabled {
      color: $text-color-head;
    }
  }
}