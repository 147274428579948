.dashboard__wrapper {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 48px 15px;
  display: flex;
  position: relative;
  gap: 100px;
  flex: 1;

  & .spinner {
    width: 100%;
    justify-content: start;
    min-height: fit-content;
  }
}

.dashboard__content {
  background-color: transparent;
  width: fit-content;
  position: sticky;
  top: 20px;
  max-width: 190px;
  min-width: 190px;
  width: 100%;
}

.dashboard__tests {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dashboard__item {
  min-height: 36px;
}

.dashboard__toggle {
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 8px 16px;
  color: $text-color-head;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    display: none;
  }

  &.current {
    background-color: #e6e6e6;
    color: $text-color-head;

    svg {
      display: block;
      transform: rotate(90deg);
      width: 10px;
      height: 14px;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      outline: $hover;
      color: #1175da;
    }
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    transform: $trans-hover;
  }

  &:not(:disabled):active {
    transform: $trans-active;
  }
}

.dashboard__stages {
  list-style: none;
  padding: 10px 0 0 40px;
  margin: 0;
  overflow: hidden;
  height: 0;
  opacity: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;

  a {
    font-size: 14px;
    line-height: 20px;
    color: #B3B3B3;
    cursor: not-allowed;

    &.done {
      cursor: pointer;
      color: $text-color-head;

      &:hover,
      &:focus {
        color: #1175da;
      }

      &:active {
        color: $text-color-head;
      }
    }


    &.active {
      color: #1175da;
      font-weight: 700;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $text-color-head;
      }

      &:active {
        color: #1175da;
      }
    }
  }
}

.dashboard__modal {
  background-color: $body-color;
  padding: 24px 16px;
  border-radius: 20px;
  min-height: 244px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 491px;
  width: 100%;

  h3 {
    font-size: 20px;
    line-height: 28px;
    font-family: $ff-unbound;
    padding-right: 50px;
  }

  p {
    font-size: 18px;
    line-height: 24px;
  }

  span {
    font-size: 16px;
    line-height: 20px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: end;

    button {
      min-height: 56px;
    }
  }
}

@media(max-width: 940px) {
  .dashboard__wrapper {
    justify-content: center;
    padding: 0;
    flex: 1;
  }

  .dashboard__content {
    display: none;
  }

  .dashboard__modal {
    max-width: 100%;
  }
}