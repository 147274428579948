.spinner {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;

  svg {
    margin-bottom: 20px;
  }

  &__text {
    font-size: 18px;
    font-weight: 500;
    color: #2c3e50;
    text-align: center;

    &:after {
      content: "...";
      display: inline-block;
      animation: dots 1.5s infinite;
    }
  }

  @keyframes dots {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
    100% {
      content: ".";
    }
  }
}