.wrapper {
  position: relative;
  background-color: $body-color;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* stylelint-disable-next-line */
  min-height: calc(100 * var(--vh, 1vh));

  @include vp-767 {
    padding-bottom: 40px;
  }

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

#svg-icons {
  position: absolute;
  left: -9999px;
  top: -9999px;
  pointer-events: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.scroll-lock-ios {
  position: fixed;
  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.mob__logo {
  display: none;

  @include vp-767 {
    display: flex;
    padding: 24px 32px;
    width: 100%;
    margin: 0 auto;
  }
}