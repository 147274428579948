.photo__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: fit-content;
}

.photo__top {
  display: flex;
  flex-direction: column;
  gap: 20px;

  a,
  button {
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;

    svg {
      transform: rotate(180deg);
      width: 10px;
      height: 14px;
    }

    &:hover,
    &:focus {
      transform: $trans-hover;
    }

    &:active {
      transform: $trans-active;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    font-family: $ff-unbound;
  }
}

.photo__examples {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 20px;
  min-height: 551px;
  padding: 31px 98px;
  min-width: 492px;
  max-width: 492px;
  position: relative;

  img {
    width: 158px;
    height: 70px;
  }

  &.photo__face {
    background-image: url("./photoUpload/face_bg.png");
  }

  &.photo__nails {
    background-image: url("./photoUpload/nails_bg.png");
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    font-family: $ff-unbound;
    text-align: center;
  }
}

.photo__tips {
  display: flex;
  flex-direction: column;
  gap: 10px;

  li {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    line-height: 24px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background-color: $color-default-white;
      border-radius: 10px;
    }
  }
}

.photo__actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: auto;

  button {
    width: 100%;
    justify-content: center;
    gap: 8px;
  }
}

.photo__upload {
  display: flex;
  align-items: center;
  gap: 8px;
}

.photo__description {
  font-size: 14px;
  line-height: 20px;
  color: #757575;
  text-align: center;
}

.photo__modal {
  width: 375px;
  height: 642px;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.photo__modal video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// .highlighter {
//   background-color: rgba(0, 255, 0, 0.25);
//   border: 2px solid #00ff00;
//   position: absolute;
//   z-index: 1000;
//   box-sizing: border-box;
// }

// .key-point {
//   width: 6px;
//   height: 6px;
//   background-color: red;
//   border-radius: 50%;
//   position: absolute;
//   z-index: 1100;
// }

.photo__capture {
  position: absolute;
  height: 120px;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1E1E1EB2;
}

.photo__capture-button {
  border: none;
  position: relative;
  outline: 3px solid #D9D9D980;
  outline-offset: 3px;
  border-radius: 50%;
  background-color: #D9D9D980;
  width: 60px;
  height: 60px;
  display: flex;
  cursor: pointer;
  z-index: 1000;

  &:hover,
  &:focus {
    background-color: $color-default-white;
    outline-color: $color-default-white;
  }

  &:active {
    transform: $trans-active;

    svg {
      transform: $trans-active;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.photo__preview {
  height: 454px;
  background-color: $body-color;
  border-radius: 10px;
  padding: 24px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 375px;
  width: 100%;

  h3 {
    font-size: 20px;
    line-height: 28px;
    font-family: $ff-unbound;
    font-weight: 700;
  }

  img {
    width: 100%;
    max-height: 232px;
    border-radius: 15px;
    object-fit: cover;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  button {
    min-height: 56px;
  }
}

.photo__analysis {
  border-radius: 20px;
  background-color: $text-color-head;
  position: relative;

  img {
    border-radius: 20px;
    height: 560px;
    width: 492px;
    object-fit: cover;
  }

  span {
    color: $color-default-white;
    position: absolute;
    bottom: 19px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.photo__failed {
  position: relative;

  img {
    filter: blur(10px);
    opacity: 0.2;
    position: relative;
  }

  &-text {
    position: absolute;
    top: 0;
    content: "";
    left: 0;
    width: 343px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: $color-default-white;

    & .photo__tips span {
      position: static;
      background-color: rgb(255, 255, 255, 0.2);
      color: $color-default-white;
    }

    h3 {
      text-align: start;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }

    button {
      width: 100%;
      min-height: 56px;
    }
  }
}

.photo__error {
  display: flex;
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: $color-default-black;
  color: $color-default-white;
  padding: 12px;
  border-radius: 10px 10px 10px 0;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  line-height: 18px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  svg {
    padding: 3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #E35502;
    color: $color-default-black;
  }

  button {
    border: none;
    background-color: transparent;
    height: fit-content;
    width: fit-content;
    padding: 0;
    min-height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      background-color: transparent;
      color: $color-default-white;
    }
  }

  &--visible {
    opacity: 1;
  }
}

@media(max-width: $mobile-width) {
  .photo {
    display: flex;
    flex: 1;
  }

  .photo__container {
    padding: 24px 0;
    height: 100%;
    width: 100%;
  }

  .photo__examples {
    max-width: 100%;
    min-width: 100%;
    padding: 32px;
    background-size: cover;
    min-height: 477px;

    &.photo__face {
      background-image: url("./photoUpload/face_bg_mob.png");
    }

    &.photo__nails {
      background-image: url("./photoUpload/nails_bg_mob.png");
    }

    label {
      display: none;
    }
  }

  .photo__description {
    display: none;
  }

  .photo__modal {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }

  .photo__capture {
    display: none;
  }

  .photo__preview {
    border-radius: 20px 20px 0 0;
    max-width: 100%;
    height: fit-content;
    margin-top: auto;

    img {
      max-height: none;
      height: auto;
    }
  }

  .photo__error {
    width: 95%;
    left: 50%;
    transform: translate(-50%, 0);
    justify-content: space-between;
  }

  .photo__analysis {
    display: flex;
    flex: 1;

    span {
      width: 100%;
      justify-content: center;
    }

    img {
      width: 100%;
      height: auto;
      min-height: 473px;
    }
  }

  .photo__failed-text {
    padding: 24px;
    gap: 20px;
    height: 100%;
    justify-content: space-between;

  }
}