.result {
  display: flex;
  width: 100%;
}

.result__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 862px;
}

.result__top {
  display: flex;
  flex-direction: column;
  gap: 20px;

  a,
  button {
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;

    svg {
      transform: rotate(180deg);
      width: 10px;
      height: 14px;
    }

    &:hover,
    &:focus {
      transform: $trans-hover;
    }

    &:active {
      transform: $trans-active;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    font-family: $ff-unbound;
  }
}

.result__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.result__status,
.result__params {
  padding: 24px;
  display: flex;
  align-items: stretch;
  background-color: $color-default-white;
  border-radius: 20px;
  min-height: 255px;


  .spinner {
    margin: auto 0;
    align-self: center;

    p {
      display: none;
    }
  }

  &.good .result__status-text button svg {
    color: #009951;
  }

  &.normal .result__status-text button svg {
    color: #E5A000;
  }

  &.bad .result__status-text button svg {
    color: #C00F0C;
  }
}

.result__status-text {
  max-width: 300px;
  width: 100%;
  min-width: 200px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  flex: 1;
  justify-content: space-between;

  h3 {
    font-size: 18px;
    line-height: 24px;
    font-family: $ff-unbound;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    color: $color-active;
  }

  button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: $ff-mont;
    cursor: pointer;
  }

  a {
    min-height: 56px;
  }

  p {
    position: absolute;
    background-color: $color-default-black;
    color: $color-default-white;
    padding: 8px 12px;
    border-radius: 10px;
    bottom: -10px;
    z-index: 1000;
    min-width: 280px;
    min-height: 70px;
    left: 85px;
    font-size: 16px;
    line-height: 20px;
  }
}



.result__status-age {
  max-width: 176px;
  width: 100%;
  min-width: 150px;
  margin: 0 16px;
  background-color: $body-color;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 15px;
  padding: 24px;
  justify-content: space-between;

  p {
    font-size: 14px;
    line-height: 20px;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;

    b {
      font-size: 40px;
      line-height: 42px;
      font-family: $ff-unbound;
    }
  }
}

.result__status-rank {
  max-width: 176px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 15px;
  padding: 24px;
  min-width: 150px;

  &.good {
    background-color: #CFF7D3;

    p,
    span {
      color: #009951;
    }

    li.filled {
      background-color: #009951;
    }
  }

  &.normal {
    background-color: #FFF1C2;

    p,
    span {
      color: #E5A000;
    }

    li.filled {
      background-color: #E5A000;
    }
  }

  &.bad {
    background-color: #FDD3D0;

    p,
    span {
      color: #C00F0C;
    }

    li.filled {
      background-color: #C00F0C;
    }
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: auto;
  }

  span {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    font-family: $ff-unbound;
    margin-bottom: 5px;

    b {
      font-size: 32px;
      line-height: 40px;
    }
  }

  ul {
    display: flex;
    gap: 7px;

    li {
      background-color: #EBFFEE;
      height: 26px;
      width: 6px;
      border-radius: 5px;
    }
  }
}

// params
.result__params {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 24px;
  border-radius: 20px;
  background-color: $color-default-white;
}

.result__params-list {
  width: 100%;
  max-width: 100%;

  ul {
    display: flex;
    gap: 8px;
    box-sizing: border-box;
    overflow-x: auto;

    li {
      display: flex;
      width: fit-content;
    }

    button {
      background-color: transparent;
      border: 2px solid #D9D9D9;
      border-radius: 10px;
      min-height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
      color: $color-active;
      font-size: 14px;
      line-height: 20px;
      padding: 12px 16px;
      width: fit-content;
      text-wrap: nowrap;

      &.active {
        border: 2px solid #1175DA;
        color: #1175DA;
      }

      &:hover,
      &:focus {
        background-color: #1175DA;
        color: $color-default-white;
        border-color: #1175DA;
      }

      &:active {
        transform: $trans-active;
      }
    }
  }
}

.result__params-bottom {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.result__params-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 49%;

  h3 {
    font-size: 18px;
    line-height: 24px;
    font-family: $ff-unbound;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }
}

.result__params-photo {
  border-radius: 20px;
  background-color: $color-active;

  img {
    border-radius: 20px;
    min-width: 200px;
    min-height: 460px;
    object-fit: cover;
    max-width: 368px;
    max-height: 460px;
  }
}

// profile
.result__profile {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 24px;
  border-radius: 20px;
  background-color: $color-default-white;

  &-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    h3 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      font-family: $ff-unbound;
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }

    span {
      margin-top: auto;
      position: relative;
      font-size: 14px;
      line-height: 20px;
      padding-left: 20px;
      display: flex;
      align-items: center;

      &::before {
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #ff6384;
        border-radius: 50%;
        background-color: lightgray;
        left: 0;
      }
    }
  }
}

.result__diag {
  min-height: 500px;
}

.result__buttons {
  display: flex;
}


@media (max-width: $tablet-width) {
  .result__status {
    flex-direction: column-reverse;
    gap: 20px;
    padding: 16px;
  }

  .result__params-list {
    ul {
      flex-wrap: wrap;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .result__status-text,
  .result__status-age,
  .result__status-rank {
    max-width: 100%;
    margin: 0;
    text-align: center;
    align-items: center;
  }

  
}

@media (max-width: $no-dashboard) {
  .result__container {
    max-width: 100%;
  }

  .result__params-list {
    ul {
      &::-webkit-scrollbar {
        display: none;
      }

      flex-wrap: nowrap;
    }
  }
}

@media (max-width: $mobile-width) {
  .result__container {
    padding: 20px 0;
  }

  .result__status {
    gap: 10px;
    justify-content: center;
  }

  .result__status-text,
  .result__status-age,
  .result__status-rank {
    text-align: start;
    align-items: flex-start;
  }

  .result__status-age {
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
  }

  .result__status-rank {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;

    p {
      display: none;
    }
  }

  .result__status-text {
    p {
      left: unset;
      right: -16px;
      max-width: 280px;
      bottom: -70px;
    }
  }

  .result__recs {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    backdrop-filter: blur(8px);
    border-top: 1px solid #D9D9D9;
    min-height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    a {
      width: 90%;
    }
  }

  .result__params-bottom {
    flex-direction: column-reverse;
    gap: 40px;
  }

  .result__params-photo {
    width: fit-content;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .result__params-details {
    max-width: 100%;

    p {
      max-height: 200px;

      overflow-y: auto;
      mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
      -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
    }
  }

  .result__recs-btn {
    display: none;
  }
}