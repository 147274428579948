// Colors
$color-default-black: #000000;
$color-default-white: #ffffff;
$body-color: #F6F6F6;
$text-color-label: #141414;
$text-color-head: #1e1e1e;
$text-color-header: #2c2c2c;
$text-error: #ec221f;
$input-color-error: #fee9e7;
$text-color-success: #14AE5C;
$input-color-succes: #CFF7D3;
$hover: 2px solid #1175DA;
$color-active: #757575;
// Typography
// переменная используемая в html для подключения скейлинга
$fs-default: 18px;
$lh-default: 24px;
$fw-default: 400;
$ff-mont: 'PT_Sans', 'Arial', sans-serif;
$ff-unbound: "Unbounded", 'Arial', sans-serif;
$fs-promo: clamp(40px, 2.5vw, 48px);
$fs-heading: clamp(18px, 2vw, 32px);
$fs-label: clamp(12px, 1.5vw, 16px);
$fs-text: clamp(14px, 2vw, 16px);
$lh-promo: clamp(52px, 2.5vw, 64px);
$lh-heading: clamp(24px, 2vw, 40px);
$lh-label: clamp(16px, 1.5vw, 20px);
$lh-text: clamp(20px, 2vw, 24px);

// Animation
$tf-default: ease;
$trans-default: 0.3s $tf-default;
$trans-active: scale(0.9);
$trans-hover: scale(1.1);
// если требуется изинг отличный от $tf-default, то переменную называем не цифрой
$trans-modal: 0.6s cubic-bezier(0.55, 0, 0.1, 1);
$trans-600: 0.6s $tf-default;

// Viewports
$mobile-width: 768px;
$no-dashboard: 940px;
$tablet-width: 1200px;

// Retina
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;